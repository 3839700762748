import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import GitHubIcon from '@netspresso/components/assets/icons/github.svg';
import { COMBINED_COMPRESSION, DISCUSSION_LINK, featureFlags, FORUM_LINK } from '@netspresso/shared';
import { useSideNavContext } from '../../../context';
import { SideNavActions } from '../../../reducers';

export const Sidebar: React.FC = () => {
  const { pathname } = useLocation();
  const [{ isOpen, isExpand }, dispatchSideNav] = useSideNavContext();

  const handleResize = () => {
    if (isOpen && window.innerWidth < 1024) {
      dispatchSideNav({ type: SideNavActions.Hide, state: { isOpen, isExpand } });
    }
  };

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <aside className={`side-bar ${isOpen ? 'open' : 'close'} ${isExpand ? 'expand' : 'shrink'}`}>
      <nav className="w-full">
        <hr />
        <span className="category inline-flex mt-4">Storage</span>
        <Link
          to="/models"
          className={`side-bar__button ${pathname.includes('models') ? 'active' : ''}`}
          role="menuitem"
        >
          <span className="material-icons mr-2 lg:mr-0 xl:mr-2">storage</span>
          <span className="menu-text">Models</span>
        </Link>
        <hr />
        <span className="category">{isExpand ? 'Model Searcher' : 'Searcher'}</span>
        <Link
          to="/datasets"
          className={`side-bar__button ${pathname.includes('datasets') ? 'active' : ''}`}
          role="menuitem"
        >
          <span className="material-icons text-xl mr-2 lg:mr-0 xl:mr-2">create_new_folder</span>
          <span className="menu-text">Datasets</span>
        </Link>
        <Link
          to="/projects"
          className={`side-bar__button ${pathname.includes('projects') ? 'active' : ''}`}
          role="menuitem"
        >
          <span className="material-symbols-outlined text-xl mr-2.5 lg:mr-0 xl:mr-2.5">hub</span>
          <span className="menu-text">Projects</span>
        </Link>
        <hr />
        <span className="category">{isExpand ? 'Model Compressor' : 'Compressor'}</span>
        {featureFlags(COMBINED_COMPRESSION) ? (
          <Link
            to="/compression/new"
            className={`side-bar__button ${pathname.includes('compression') ? 'active' : ''}`}
            role="menuitem"
          >
            <span className="material-icons text-xl mr-2 lg:mr-0 xl:mr-2">compress</span>
            <span className="menu-text">Compress</span>
          </Link>
        ) : (
          <a
            href={`${process.env.REACT_APP_COMPRESSOR_URL}/models`}
            className="side-bar__button"
            target="_self"
            role="menuitem"
            rel="noreferrer"
          >
            <span className="material-icons text-xl mr-2 lg:mr-0 xl:mr-2">compress</span>
            <span className="menu-text">Compress</span>
          </a>
        )}
        <hr />
        <span className="category">{isExpand ? 'Model Launcher' : 'Launcher'}</span>
        <Link
          to="/convert"
          className={`side-bar__button ${pathname.includes('convert') ? 'active' : ''}`}
          role="menuitem"
        >
          <span className="material-icons text-xl mr-2 lg:mr-0 xl:mr-2">autorenew</span>
          <span className="text-with-tag">
            <span className="menu-text">Convert</span>
          </span>
        </Link>
        <Link
          to="/package"
          className={`side-bar__button ${pathname.includes('package') ? 'active' : ''}`}
          role="menuitem"
        >
          <span className="material-symbols-outlined text-xl mr-2 lg:mr-0 xl:mr-2">sim_card</span>
          <span className="text-with-tag">
            <span className="menu-text">Package</span>
          </span>
        </Link>
      </nav>
      <div className="footer">
        <hr />
        <span className="category inline-flex mt-4">Link</span>
        <ul>
          <li>
            <a
              href={process.env.REACT_APP_PORTAL_URL}
              className="side-bar__button"
              target="_self"
              role="menuitem"
              rel="noreferrer"
            >
              <span className="material-icons mr-2 lg:mr-0 xl:mr-2">language</span>
              <span className="menu-text">Portal</span>
            </a>
          </li>
          <li>
            <a href={FORUM_LINK} className="side-bar__button" target="_blank" role="menuitem" rel="noreferrer">
              <span className="material-icons mr-2 lg:mr-0 xl:mr-2">description</span>
              <span className="menu-text">Documentation</span>
            </a>
          </li>
          <li>
            <a href={DISCUSSION_LINK} className="side-bar__button" target="_blank" role="menuitem" rel="noreferrer">
              <GitHubIcon />
              <span className="menu-text ml-2">Discussion Forum</span>
            </a>
          </li>
        </ul>
      </div>
    </aside>
  );
};
